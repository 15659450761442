<template>
  <vx-card>
    <div>
      <h3>{{ dataTodo.title }}</h3>
    </div>
    <vs-divider  />
    <div class="mt-5">
      {{ dataTodo.text }}
    </div>
    <div>
      <a
      v-if="fileMix.ext"
      :download="fileMix.name"
      :href="`data:image/${fileMix.ext};base64,`+`${fileMix.data}`"
      >دانلود فایل پیوست</a>
    </div>
  </vx-card>

</template>

<script>
export default {
  name: "Show",
  data () {
    return {
      dataTodo: [],
      fileAddr: '',
      fileMix: null
    }
  },
  methods: {
    showTod (id = this.$route.params.id) {
      this.$http.get(`/admin/salestodo/${id}`).then((res) => {
        const fileName = res.data.params.data.file
        const fileExt = fileName.split('.')
        this.fileMix = {
          data: res.data.params.attachment_content,
          ext: fileExt[1],
          name: fileExt[0]
        }
        console.log('res img', fileExt)
        this.dataTodo = res.data.params.data
        this.fileAddr = atob(res.data.params.attachment_content)
      }).catch((err) => {
        console.log('err', err)
      })
    }
  },
  mounted() {
    this.showTod()
  }
}
</script>

<style scoped>

</style>
